export const simpleData = [
  {
    key: 'Key1',
    value: 28700000000,
  },
  {
    key: 'Key2',
    value: 5640000000,
  },
  {
    key: 'Key3',
    value: 3995300000,
  },
  {
    key: 'Key4',
    value: 2901700000,
  },
  {
    key: 'Key5',
    value: 852000000,
  },
  {
    key: 'Key6',
    value: 124000000,
  },
  {
    key: 'Key7',
    value: 95910000,
  },
];

export const ministers = [
  {
    key: 'Action et comptes publics',
    value: 190664000000,
  },
  {
    key: 'Éducation nationale et jeunesse',
    value: 73222298716,
  },
  {
    key: 'Économie et finances',
    value: 67120005797,
  },
  {
    key: 'Armées',
    value: 48295663621,
  },
  {
    key: 'Transition écologique et solidaire',
    value: 27089931724,
  },
  {
    key: 'Intérieur',
    value: 26653305291,
  },
  {
    key: 'Enseignement supérieur, recherche et innovation',
    value: 25509067377,
  },
  {
    key: 'Cohésion des territoires et relations avec les collectivités territoriales',
    value: 19192655720,
  },
  {
    key: 'Services du Premier ministre',
    value: 16056991134,
  },
  {
    key: 'Solidarités et santé',
    value: 14383112645,
  },
  {
    key: 'Travail',
    value: 12771587229,
  },
  {
    key: 'Justice',
    value: 9388292378,
  },
  {
    key: 'Europe et affaires étrangères',
    value: 5014870806,
  },
  {
    key: 'Agriculture et alimentation',
    value: 4926444454,
  },
  {
    key: 'Culture',
    value: 3659769957,
  },
  {
    key: 'Outre-mer',
    value: 2409471691,
  },
  {
    key: 'Sports',
    value: 563976723,
  },
];

// The method create metadata for the columns
// Which columns include OMVs, which datetime, which are nominal and which quantitative (including the range)
// For example {name: "Country", type: "nominal", range: 8}
export const findMetaData = (dataset) => {
  const metadata = {};

  // For each row in the dataset
  dataset.forEach((row) => {
    // For each column in the dataset
    for (const col in row) {
      if (row.hasOwnProperty(col)) {
        if (!metadata[col]) {
          metadata[col] = {
            type: null,
            values: new Set(),
            min: null,
            max: null,
          };
        }

        // add the values of the column
        let value = row[col];
        metadata[col].values.add(value);

        // Update data type only if it's not already set
        if (metadata[col].type === null) {
          if ((typeof value === 'number' || typeof value === 'bigint') && !isUnixTimestamp(value)) {
            metadata[col].type = 'quantitative';
          } else if (isUnixTimestamp(value)) {
            metadata[col].type = 'date';
            // determine if a value is a string that can be successfully parsed as a date,
            // and also that is not being a simple numeric value
          } else if (typeof value === 'string' && !isNaN(Date.parse(value)) && isNaN(value)) {
            metadata[col].type = 'date';
          } else {
            metadata[col].type = 'nominal';
          }
        }

        // Calculate range for quantitative data
        if (metadata[col].type === 'quantitative') {
          if ((metadata[col].min === null || value < metadata[col].min) && value > 0) {
            metadata[col].min = value;
          }
          if (metadata[col].max === null || value > metadata[col].max) {
            metadata[col].max = value;
          }
        }

        // Convert string to Date object for date data
        if (metadata[col].type === 'date') {
          value = new Date(value);
          if (metadata[col].min === null || value < metadata[col].min) {
            metadata[col].min = value;
          }
          if (metadata[col].max === null || value > metadata[col].max) {
            metadata[col].max = value;
          }
        }
      }
    }
  });

  // Post-processing for range and count of distinct values
  Object.keys(metadata).forEach((col) => {
    if (metadata[col].type === 'quantitative') {
      const difference = metadata[col].max - metadata[col].min;
      // Check if the number is a float
      if (typeof difference !== 'bigint' && difference % 1 !== 0) {
        // It's a float, format it to 2 decimal places
        metadata[col].range = parseFloat(difference.toFixed(2));
      } else {
        // It's an integer, return it as is
        metadata[col].range = difference;
      }
      // if min and max values differ more than 4 exponents set omv (Order of Magnitude Value) as true;
      metadata[col].omv = (logOfBigInt(metadata[col].max) - logOfBigInt(metadata[col].min) >= 4);
    } else if (metadata[col].type === 'date') {
      metadata[col].range = differenceInDays(metadata[col].max, metadata[col].min);
    } else {
      metadata[col].range = metadata[col].values.size;
    }
    delete metadata[col].values;
  });

  return metadata;
};


// method to check if a value is a timestamp
const isUnixTimestamp = (value) => {
  if (typeof value !== 'number') {
    return false;
  }
  const date = new Date(value);
  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    return false;
  }
  // Check for a reasonable range (e.g., 1970 - 2100)
  // For our dataset we will define a small range
  // Need to be changed with different dataset
  const start = new Date('1995-01-01').getTime();
  const end = new Date('2030-01-01').getTime();
  return value >= start && value <= end;
};


const logOfBigInt = (bigintValue) => {
  // Convert BigInt to String to count digits for a rough approximation
  const digits = bigintValue.toString().length;
  // Approximate log10(value) by the number of digits minus 1
  // This approximation becomes less accurate with very large numbers
  return digits - 1;
};

function getMantissa(bigIntValue, digits) {
  // Convert the BigInt to a string to access individual digits
  const bigIntStr = bigIntValue.toString();

  // Extract the mantissa based on digits
  const firstDigits = bigIntStr.slice(0, digits + 2);

  // Convert the first digit back to a BigInt (or Number, depending on your needs)
  const mantissa = Number(firstDigits)/100; // As BigInt
  // const mantissa = Number(firstDigit); // As Number, if you prefer

  return mantissa;
}

function formatDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1; // getMonth() returns a zero-based index, so add 1
  const year = date.getFullYear();

  // Ensure day and month are two digits
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}


// method to find the difference in days between timestamps
const differenceInDays = (timestamp1, timestamp2) => {
  // One day in milliseconds
  const oneDay = 24 * 60 * 60 * 1000;

  // Convert timestamps to Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(date2 - date1);

  // Convert the difference to days
  return diffInMs / oneDay;
};

export const displayRange = (type, range) => {
  if (type == 'date') {
    return range + ' days';
  } else if (type == 'quantitative' && typeof range === 'bigint') {
    const scientificNumber = bigIntToScientificNotation(range);
    return scientificNumber;
  } else {
    return range;
  }
};

const bigIntToScientificNotation = (bigIntValue) => {
  const bigIntStr = bigIntValue.toString();
  const length = bigIntStr.length;

  if (length <= 15) { // 15 is a somewhat arbitrary limit for converting to Number
    // If within safe integer range, convert directly to Number and format
    const numberValue = Number(bigIntStr);
    return numberValue.toExponential(1); // '1' specifies one digit after the decimal point
  } else {
    // Manual formatting for larger than safe integer, ensuring two digits in the mantissa
    const digits = bigIntStr.slice(0, 1) + '.' + bigIntStr.slice(1, 2); // Adjusted for twtypeo digits in the mantissa
    const exponent = length - 1;
    return `${digits}e+${exponent}`;
  }
};


export const data = [...simpleData];
// export const data = [...ministeres]

export const getMantissaExponent = (digits, dataset, omv) => {
  const dataMantExp = []; // Dateset with mantissa and exponent
  // selected columns removing the omv value from the dataset
  const keys = Object.keys(dataset[0]).filter((key) => key !== omv);
  dataset.map((d) => {
    const exponent = Math.floor(logOfBigInt(d[omv])) - (Math.floor(logOfBigInt(d[omv])) % digits);
    // Convert d[omv] from BigInt to Number before division
    const mantissa = getMantissa(d[omv], digits);
    // for security reasons images have to be in the dataset
    // for countable symbols
    // const dominoMantissa = '/countableSymbols/dominos/domino-'+ Math.trunc(mantissa) +'.png';
    // const dominoExponent = '/countableSymbols/dominos/domino-'+ Math.trunc(exponent) +'.png';
    // const starMantissa = '/countableSymbols/stars/star-'+ Math.trunc(mantissa) +'.png';
    // const starExponent = '/countableSymbols/stars/star-'+ Math.trunc(exponent) +'.png';
    // const textureMantissa = '/countableSymbols/countableTextures/texture-'+ Math.trunc(mantissa) +'.png';
    // const textureExponent = '/countableSymbols/countableTextures/texture-'+ Math.trunc(exponent) +'.png';
    // add the selected columns
    const newRow = {};
    keys.map((k) => {
      // if date type
      if (isUnixTimestamp(d[k])) {
        newRow[k] = new Date(d[k]);
      } else if (typeof d[k] === 'number' && !Number.isInteger(d[k])) {
        // If it's a "float", format it to keep only two digits after the decimal
        newRow[k] = parseFloat(d[k].toFixed(2));
      } else {
      // else no date type
        newRow[k] = d[k];
      }
    });
    newRow.value = d[omv];
    newRow.exponent = exponent;
    newRow.mantissa = mantissa;
    newRow['exponent+mantissa'] = exponent + mantissa/10**digits;
    // newRow.dominoMantissa = dominoMantissa;
    // newRow.dominoExponent = dominoExponent;
    // newRow.starMantissa = starMantissa;
    // newRow.starExponent = starExponent;
    // newRow.textureMantissa = textureMantissa;
    // newRow.textureExponent = textureExponent;
    dataMantExp.push(newRow);
  });
  console.log('data: ', dataMantExp);
  return dataMantExp;
};

// this method creates units by performing a "disagreegation" transformation to the data
// creating additional entries for each key, based on the value of the given dimension.
// For example if the mantissa for key2 is 6, 6 entries in the dataset will be
// {key: key2, mantissa: 6, ...}. We apply stacking after to create unit visualizations.
export const unitData = (dataset, dimension) => {
  const unitsData = [];
  dataset.map((d) => {
    for (let i = 1; i <= d[dimension]; i++) {
      unitsData.push(d);
    }
  });
  return unitsData;
};


// Function to find the quantitative field with the highest range
export const findOMVwithHighestRange = (obj) => {
  let highestRangeField = null;
  let highestRange = -Infinity;

  // Iterate over the object to find quantitative fields and their ranges
  Object.entries(obj).forEach(([key, value]) => {
    if (value.type === 'quantitative' && value.range > highestRange) {
      highestRange = value.range;
      highestRangeField = {key, ...value};
    }
  });

  return highestRangeField;
};

// Function to find the non-quantitative field with the lowest range
export const findNonOMVFieldWithLowestRange = (obj) => {
  let lowestRangeField = null;
  let lowestRange = Infinity;

  // Iterate over the object to find non-quantitative fields and their ranges
  Object.entries(obj).forEach(([key, value]) => {
    if (value.type !== 'quantitative' && typeof value.range !== 'bigint' && value.range < lowestRange) {
      lowestRange = value.range;
      lowestRangeField = {key, ...value};
    }
  });

  return lowestRangeField;
};

// Function to find the non-omv fields
export const createColumns = (obj) => {
  // to do: calculate max and rand for mantissa and exponent
  const columns = [
    {key: 'mantissa', type: 'quantitative', min: 0, max: null, range: null},
    {key: 'exponent', type: 'quantitative', min: 0, max: null, range: null},
  ];
  // Iterate over the object to find non-quantitative fields that have more than one value
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value.range !== 'bigint' && value.range > 1) {
      columns.push({key, ...value});
    }
  });
  return columns;
};

export const assignIdentityChannelToKey = () => {
  // this method depends on the names and the number of the images that are used
  const keys = data.map((item) => item.key);
  // Works up to 10 keys
  const chanToKeys = [];
  // if keys < 10
  if (keys.length <= 10) {
    // add into the list chanToKeys a dictionary with the elements key, texture, orientation
    for (let i=1; i<= keys.length; i++) {
      chanToKeys.push({'key': keys[i-1], 'texture': '/textures/texture-'+i+'.png', 'orientation': '/orientation/orientation-'+i+'.png'});
    }
  } else {
    // if keys > 10
    // print error message
    console.log('You have to add more images');
  }
  return chanToKeys;
};
