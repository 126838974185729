import React from 'react';
import './Modal.css'; // Make sure to include any necessary CSS
import { MdClose } from "react-icons/md";
import { FaBugs } from "react-icons/fa6";
// import { IoMdWarning } from "react-icons/io";

const Modal = ({ closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
            <button id="close-modal" onClick={closeModal}><MdClose /></button>
        </div>
        <div className="modal-info">
            <h2>Welcome to <a href="https://osf.io/92vrw?view_only=061aa4a15028449b91304339df89d40c">OMVis</a>!</h2>
            <p>This is an <a href="https://gitlab.inria.fr/aviz/order-of-magnitude"><b>open-source</b></a> tool we have created to support the exploration of the design space for visualizing data with large value ranges, by seperating the Order of Magnitude Values (OMVs) into mantissa and exponent.</p>
            <br/>
            <p>If you want to learn more about our project, the full paper is available at <a href="https://arxiv.org/abs/2404.15150"><b>arXiv</b></a> and we would be happy to answer any question at a.batziakoudi@berger-levrault.com.</p>
            <br/>
            <p>Expect bugs <FaBugs /> !</p>
            <br/>
        </div>
      </div>
    </div>
  );
};

export default Modal;